var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-page',{staticClass:"page-interview-process",attrs:{"loading":_vm.pageLoading}},[(_vm.interview.id)?[_c('div',{staticClass:"page-interview-process-header"},[_c('a-row',{attrs:{"gutter":{ lg: 20, sm: 10, xs: 10 }}},[_c('a-col',{attrs:{"span":12}},[_c('a-row',{attrs:{"gutter":[0, 20]}},[_c('a-col',{attrs:{"span":24}},[_c('a-row',{attrs:{"type":"flex","align":"middle","gutter":[0, 10]}},[_c('a-col',{attrs:{"span":24}},[_c('span',[_vm._v(_vm._s(_vm.interview.company.name))])]),_c('a-col',{attrs:{"span":24}},[_c('PageTitle',{staticStyle:{"margin-bottom":"0px"},attrs:{"tag":"h2","size":"25"}},[_vm._v(" "+_vm._s(_vm.interview.name)+" ")])],1)],1)],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('div',{staticClass:"page-interview-process-header-avatar"},[_c('a-avatar',{attrs:{"size":65,"src":_vm.interview.company.logo}},[_c('icon-user-default-avatar')],1)],1)])],1)],1),_c('card',{staticClass:"page-interview-process-content"},[_c('a-row',{staticClass:"page-interview-process-content-row",attrs:{"type":"flex","gutter":[20, 20]}},[_c('a-col',{staticClass:"d-flex flex-column",attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('progress-bar',{staticClass:"orange mb-10",attrs:{"percent":((_vm.step + 1) * 100) / _vm.interview.questions.length}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(((_vm.step + 1) + "/" + (_vm.interview.questions.length) + " " + (_vm.$t('questions'))))+" ")])],2),_c('a-row',{staticClass:"mb-20",attrs:{"gitter":20}},[_c('a-col',{attrs:{"span":12}},[_c('page-title',{staticClass:"mb-0-i",attrs:{"tag":"div","size":"16"}},[_vm._v(" "+_vm._s(((_vm.$t('question')) + " " + (_vm.step + 1)))+" ")])],1)],1),(
              _vm.question.preparationTime &&
                _vm.question.preparationTime !== 'Unlim' &&
                !_vm.isPreparation &&
                !_vm.stepPreparePassed
            )?_c('div',[_c('icon-time',{attrs:{"width":"50"}}),_c('page-title',{staticStyle:{"white-space":"pre-line","line-height":"1.4"},attrs:{"tag":"div","size":"20"}},[_vm._v(" "+_vm._s(_vm.$t('Time to prepare for the answer is limited!', { time: _vm.question.preparationTime }))+" ")])],1):[_c('page-title',{staticStyle:{"white-space":"pre-line"},attrs:{"tag":"div","size":"20"}},[_vm._v(" "+_vm._s(_vm.question.question)+" ")]),_c('a-row',{attrs:{"gutter":[20, 20]}},[(_vm.question.imageFilePreview)?_c('a-col',{attrs:{"span":24,"sm":_vm.question.videoQuestionLink ? 12 : 24}},[_c('div',{staticClass:"interview-image-question"},[_c('a',{staticStyle:{"cursor":"zoom-in"},attrs:{"href":_vm.question.imageFilePreview,"data-fancybox":"gallery"}},[_c('img',{attrs:{"src":_vm.question.imageFilePreview,"alt":_vm.question.question}})])])]):_vm._e(),(_vm.question.videoQuestionLink)?_c('a-col',{attrs:{"span":24,"sm":_vm.question.imageFilePreview ? 12 : 24}},[_c('div',{staticClass:"interview-video-question"},[_c('video',{key:_vm.question.videoQuestionLink,attrs:{"controls":"","crossorigin":"","playsinline":"","autoplay":"","width":"100%"}},[_c('source',{attrs:{"src":_vm.question.videoQuestionLink}})])])]):_vm._e()],1)]],2),_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-row',{staticClass:"mb-20",attrs:{"gitter":20}},[_c('a-col',{attrs:{"span":12}},[_c('page-title',{staticClass:"mb-0-i",attrs:{"tag":"div","size":"16"}},[_vm._v(" "+_vm._s(_vm.$t('Answer time'))+": ")])],1),_c('a-col',{attrs:{"span":12}},[_c('div',{staticClass:"grayish-blue-400 d-flex align-items-center justify-content-end"},[_c('icon-time',{staticStyle:{"fill":"currentColor"},attrs:{"width":"14px","heigh":"14px"}}),(_vm.stepDuration)?_c('span',{staticClass:"timer-value-wrapper"},[_c('b',{class:[
                      'timer-value',
                      {
                        'timer-value-large':
                          Math.floor(
                            _vm.currentStepRecordDuration - _vm.stepDuration
                          ) <= 10 &&
                          Math.floor(
                            _vm.currentStepRecordDuration - _vm.stepDuration
                          ) !== 0
                      }
                    ]},[_vm._v(" "+_vm._s(_vm._f("timeInHours")((_vm.currentStepRecordDuration - _vm.stepDuration)))+" ")])]):_c('span',[_c('b',[_vm._v(_vm._s(_vm.question.time))])])],1)])],1),_c('div',{staticClass:"video-placeholder"},[(_vm.question.type === 'VIDEO')?[(_vm.useFile && _vm.filePreviewSrc)?_c('video',{staticClass:"ios-video-preview",attrs:{"src":_vm.filePreviewSrc,"controls":"","crossorigin":"","playsinline":""}}):_vm._e(),(!_vm.useFile)?_c('video-record',{ref:"videoRecorder",attrs:{"duration":_vm.currentStepRecordDuration,"preview-exists":!!_vm.data[_vm.step],"preview-play":_vm.previewPlay},on:{"player-init":_vm.onPlayerInit,"device-ready":_vm.onDeviceReady,"device-error":_vm.onDeviceError,"use-file":_vm.handleUserFile,"progress-record":_vm.onProgressRecordChange,"start-recording":_vm.onStartRecording,"finish-recording":_vm.onFinishRecording,"preview-play":_vm.togglePlayPreview}}):_vm._e()]:_vm._e(),(_vm.question.type === 'TEST' && _vm.stepPreparePassed)?_c('interview-quiz',{ref:"interviewQuiz",attrs:{"options":_vm.question.tests.map(function (ref) {
                                var test_id = ref.test_id;
                                var text = ref.text;

                                return ({
                  label: text,
                  value: test_id
                });
})},on:{"result":_vm.onGetQuizResult}}):_vm._e(),(_vm.question.type === 'CODE' && _vm.stepPreparePassed)?_c('div',[_c('page-title',{attrs:{"tag":"div","size":"16"}},[_vm._v(" "+_vm._s(((_vm.$t('write_your_answer_here')) + ":"))+" ")]),_c('code-editor',{ref:"interviewCodeEditor",on:{"result":_vm.onGetCodeResult}})],1):_vm._e(),(_vm.question.type === 'TEXT' && _vm.stepPreparePassed)?_c('div',[_c('page-title',{attrs:{"tag":"div","size":"16"}},[_vm._v(" "+_vm._s(((_vm.$t('write_your_answer_here')) + ":"))+" ")]),_c('text-editor',{ref:"interviewTextEditor",on:{"result":_vm.onGetTextResult}})],1):_vm._e()],2)],1),_c('a-divider'),_c('a-col',{attrs:{"span":24}},[(
              _vm.question.preparationTime &&
                _vm.question.preparationTime !== 'Unlim' &&
                !_vm.stepPreparePassed
            )?_c('div',{staticClass:"page-interview-process-prepare-actions"},[_c('app-button',{staticClass:"mr-10 hover-light",style:({
                backgroundColor: _vm.style.btnColor,
                borderColor: _vm.style.btnColor
              }),attrs:{"type":"primary","size":"large","disabled":_vm.isPreparation && !_vm.stepPreparePassed},on:{"click":_vm.startPreparation}},[(_vm.isPreparation && !_vm.stepPreparePassed)?[_vm._v(" "+_vm._s(_vm.$t('Left'))+" "+_vm._s(_vm._f("timeInHours")((_vm.currentStepPrepareDuration - _vm.stepPrepareDuration)))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('Show question'))+" ")]],2),(_vm.isPreparation && !_vm.stepPreparePassed)?_c('app-button',{staticClass:"mt-10",attrs:{"type":"link","size":"small"},on:{"click":_vm.onSkipPreparationTime}},[_vm._v(" "+_vm._s(_vm.$t('Skip preparation time'))+" ")]):_vm._e()],1):[(_vm.question.type === 'VIDEO')?_c('div',{staticClass:"page-interview-process-actions"},[(_vm.deviceReady)?[(!_vm.data[_vm.step] && !_vm.useFile)?[(!_vm.videoRecord)?_c('app-button',{staticClass:"mr-10 hover-light",style:({
                      backgroundColor: _vm.style.btnColor,
                      borderColor: _vm.style.btnColor
                    }),attrs:{"type":"primary","size":"large","loading":_vm.videoRecordStartLoading || _vm.loadDeviceUpdate},on:{"click":_vm.startRecord}},[_vm._v(" "+_vm._s(_vm.$t('record_video'))+" ")]):_vm._e(),(_vm.videoRecord)?_c('app-button',{staticClass:"mr-10 hover-light",style:({
                      backgroundColor: _vm.style.btnColor,
                      borderColor: _vm.style.btnColor
                    }),attrs:{"type":"primary","size":"large"},on:{"click":_vm.stopRecord}},[_vm._v(" "+_vm._s(_vm.$t('stop_record_video'))+" ")]):_vm._e()]:_vm._e(),(!_vm.data[_vm.step] && _vm.useFile)?_c('app-button',{staticClass:"mr-10 hover-light",style:({
                    backgroundColor: _vm.style.btnColor,
                    borderColor: _vm.style.btnColor
                  }),attrs:{"type":"primary","size":"large"},on:{"click":_vm.handleChoseFile}},[_vm._v(" "+_vm._s(_vm.isMobileSafari ? _vm.$t('record_video') : _vm.$t('chose_file'))+" ")]):_vm._e(),(_vm.data[_vm.step] && !_vm.isFinally)?_c('app-button',{staticClass:"mr-10 hover-light",style:({
                    backgroundColor: _vm.style.btnColor,
                    borderColor: _vm.style.btnColor
                  }),attrs:{"type":"primary","size":"large","loading":_vm.videoRecordStartLoading},on:{"click":_vm.handleNext}},[_vm._v(" "+_vm._s(_vm.$t('next_question'))+" ")]):_vm._e(),(
                    _vm.data.length === _vm.interview.questions.length && _vm.isFinally
                  )?_c('app-button',{staticClass:"mr-10 hover-light",style:({
                    backgroundColor: _vm.style.btnColor,
                    borderColor: _vm.style.btnColor
                  }),attrs:{"type":"primary","size":"large"},on:{"click":_vm.handleFinish}},[_vm._v(" "+_vm._s(_vm.$t('finish'))+" ")]):_vm._e(),(
                    _vm.data[_vm.step] &&
                      _vm.question.retake &&
                      _vm.question.preparationTime === 'Unlim'
                  )?_c('a-popconfirm',{attrs:{"title":_vm.$t('are_you_sure_you_want_to_overwrite_the_video')},on:{"confirm":_vm.reRecord}},[_c('app-button',{style:({
                      borderColor: _vm.style.btnColor
                    }),attrs:{"type":"primary","size":"large","ghost":""}},[_c('icon-reload',{staticClass:"mr-5",attrs:{"width":"14","heigh":"14","fill":_vm.style.btnColor}}),_vm._v(" "+_vm._s(_vm.$t('re_take'))+" ")],1)],1):_vm._e()]:_vm._e()],2):_vm._e(),(['TEST', 'TEXT', 'CODE'].indexOf(_vm.question.type) >= 0)?_c('div',{staticClass:"page-interview-process-actions"},[(!_vm.isFinally)?_c('app-button',{staticClass:"mr-10 hover-light",style:({
                  backgroundColor: _vm.style.btnColor,
                  borderColor: _vm.style.btnColor
                }),attrs:{"type":"primary","size":"large"},on:{"click":_vm.handleNext}},[_vm._v(" "+_vm._s(_vm.$t('next_question'))+" ")]):_vm._e(),(_vm.isFinally)?_c('app-button',{staticClass:"mr-10 hover-light",style:({
                  backgroundColor: _vm.style.btnColor,
                  borderColor: _vm.style.btnColor
                }),attrs:{"type":"primary","size":"large"},on:{"click":_vm.handleFinish}},[_vm._v(" "+_vm._s(_vm.$t('finish'))+" ")]):_vm._e()],1):_vm._e()]],2)],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }